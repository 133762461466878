import * as Sentry from "@sentry/browser";
import * as SentryIntegrations from "@sentry/integrations";
Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
    environment: import.meta.env.APP_ENV,
    integrations: [
        Sentry.replayIntegration({
            maskAllText: false,
            maskAllInputs: false,
            blockAllMedia: false,
        }),
        Sentry.replayCanvasIntegration(),
        SentryIntegrations.captureConsoleIntegration({
            // Add 'warn', 'error', 'debug', 'assert' console output types to Sentry
            // and additionally possible to add 'log' and 'info' console output types to Sentry if required
            levels: ['log', 'info', 'warn', 'error', 'debug', 'assert'],
        }),
        SentryIntegrations.contextLinesIntegration({
            frameContextLines: 50,
        }),
        SentryIntegrations.httpClientIntegration(),
    ],
    logErrors: true,
    sendDefaultPii: true,
    tracesSampleRate: 0.1,
    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,

    trackComponents: true,

    denyUrls:
        [
            // All browser extensions
            /extensions\//i,
            /^safari-extension:\/\//i,
            /^safari-web-extension:\/\//i,
            /^moz-extension:\/\//i,
            /^chrome:\/\//i,
            /^chrome-extension:\/\//i,
            /moz-extension/i,
        ],
})
